import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

export default function Name() { 
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/')
    };
    return (
        <TornadoWarning>
            <CheeseBurg onClick={handleClick}>selena bao</CheeseBurg>
            <CornDog>appleselena [at] gmail [dot] com  // Great Falls, Virginia</CornDog>
        </TornadoWarning>
    )
}

const TornadoWarning = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
`

const CheeseBurg = styled.section`
    cursor: pointer;
    color: #595959;
    font-family: 'Inter';
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.24px;
`;

const CornDog = styled.section`
    color: #8C8C8C;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`