import styled from "styled-components"

export default function MyWorksText(){
    return (
        <Text>
            <P>
                Under construction!!
            </P>
        </Text>
    );
}

const Text = styled.div`
    animation: example2 .3s ease-in-out;

    @keyframes example2 {
        0%{ 
            transform: translateY(200%); 		
        }
        100% { 
            transform: translateY(0%); 
        }
    }
`

const Highlight = styled.div`
    inline-size: min-content;
    text-weight: bold;
    padding: 0px 2px 0px 2px;
    background: linear-gradient(to bottom right, #ADD8E6, #FFC0CB) 5%;
    display: inline-flex;
`
const H1 = styled.div`
    float: left;
    margin-bottom: 20px;
    margin-top: 20px;
    display: inline-flex;
    text-align: left !important;
    font-size: 24px;
    width: 100%;
`
const P = styled.div`
    float: left;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: left !important;
    width: 100%;
`