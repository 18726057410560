import "../App.css";

export default function Social({ social_icon, social_link } : { social_icon: string, social_link: string}) {
    return (
        <a href={social_link} target="_blank" rel="noopener noreferrer">
            <button className={"bg-[#ECECEC] px-4 w-14 h-14 overflow-hidden rounded-full items-center"} >
                    <img src={social_icon} alt=""></img>
            </button> 
        </a>
    )
}