import "../App.css";
import { useNavigate } from 'react-router-dom';

export default function Button({ button_name } : { button_name: string }) {
    const navigate = useNavigate();
    const handleClick = () => {
        const spaceless = button_name.replace(" ", "-");
        navigate('/' + spaceless)
    };

    const justify = " justify-end"
    return (
        <button onClick={handleClick} className={"bg-[#ECECEC] px-5 w-40 h-12 inline-flex w-fit rounded-2xl items-center" + justify} >
            {button_name}
        </button>
    )
}