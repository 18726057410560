import styled from "styled-components"

export default function AboutText(){
    return (
        <Text>
            <H1>Hi!</H1>
            <P>I'm Selena, A student studying computer science at the 
                University of Maryland, College Park. I'm graduating Spring, 2024!
                <br/><br/>
                I like fullstack development. I did some mobile (iOS) development briefly, but the
                majority of my time has been spent in the realm of fullstack.
                <br/><br/>
                This site is still in the works. I have a lot to add, so come back soon! :	&#41;
            </P>
        </Text>
    );
}
const Text = styled.div`

  animation: easeInAnimation .3s ease-in-out forwards;

    @keyframes easeInAnimation {
        0%{ 
            transform: translateY(200%); 		
        }
        100% { 
            transform: translateY(0%); 
        }
    }
`
const Highlight = styled.div`
    inline-size: min-content;
    text-weight: bold;
    padding: 0px 2px 0px 2px;
    background: linear-gradient(to bottom right, #ADD8E6, #FFC0CB) 5%;
    display: inline-flex;
`
const H1 = styled.div`
    float: left;
    margin-bottom: 20px;
    margin-top: 20px;
    display: inline-flex;
    text-align: left !important;
    font-size: 24px;
    width: 100%;
`
const P = styled.div`
    float: left;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: left !important;
    width: 100%;
`