import React from 'react';
import Name from './components/name';
import Button from './components/button';
import Social from './components/social';
import styled from "styled-components";
import './App.css';
import AboutText from './pages/about-text';
import ExperienceText from './pages/experience-text';
import MyWorksText from './pages/my-works-text';

const mapping:any = {
    "about": <AboutText/>,
    "experience": <ExperienceText/>,
    "my works": <MyWorksText/>
};

export default function Page({ page_name } : { page_name: string }) {

    return (
        <div className="flex justify-center gap-7 h-screen">
            <div className='inline-flex flex-col justify-center gap-7 h-full'>
                <Name />
                <div className="inline-flex flex-col gap-3">
                    <Button button_name='experience' />
                    <Button button_name='my works' /> 
                </div>
                <div className="inline-flex gap-3">
                    <Social social_icon="/linkedin.svg" social_link="https://www.linkedin.com/in/selena-bao/" />
                    <Social social_icon="/github.svg" social_link="https://github.com/spectregray" />
                </div>  
            </div>
            <div className="w-37">
                &nbsp;
            </div>
            <div className='flex w-2/5 h-screen'>
                <Frame>
                    {mapping[page_name]}
                </Frame>
                <HardLight />
                <BlurredGradient/>
            </div>
        </div>
    )
}

const Frame = styled.div`
    position: relative;
    z-index: 2;
    display: inline-flex;
    width: 38vw;
    margin-left: auto;
    margin-right: auto;
    height: 120vh;
    padding: 113px 74px 651px 55px;
    flex-direction: column;
    justify-content: left;
    flex-shrink: 0;
    background: rgba(255, 255, 255, 0.66);
`

const HardLight = styled.div`
    position: fixed;
    z-index: 1;
    width: 40vw;
    height: 120vh;
    flex-shrink: 0;
    background: url(/base-gradient.svg),lightgray 50% / cover no-repeat;
    background-repeat:no-repeat;
    background-size: cover;
    mix-blend-mode: overlay;
    object-fit: fill;
    filter: blur(30px);
`

const BlurredGradient = styled.div`
    position: fixed;
    z-index: 0;
    width: 40vw;
    height: 120vh;
    flex-shrink: 0;
    background: url(/base-gradient.svg), lightgray 50% / cover no-repeat;
    background-repeat:no-repeat;object-fit: fill;
    background-size: cover;
    filter: blur(150px);
`